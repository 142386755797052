* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  scroll-behavior: smooth;
  text-decoration: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

html,
body {
  overflow-x: hidden;
  height: auto;
  scroll-behavior: smooth !important ;
}

html {
  scroll-behavior: smooth;

}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  box-decoration-break: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  overflow-wrap: break-word !important;
  font-family: $font-default;
  font-weight: normal;
  font-style: normal;
  color: #686868;
  -webkit-tap-highlight-color: transparent;
}

a{
  -webkit-tap-highlight-color: transparent;
}

button {
  border: 0;
  outline: none;
  text-decoration: none;
}

::-webkit-scrollbar-track {
  border: 0px solid black;
  background-color: transparent;
  //background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
  @media (max-width: 480px) {
    width: 5px;
    height: 5px;
  }
  // margin-right:10rem;
  //	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
