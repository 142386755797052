.app {
  font-family: "Poppins", sans-serif;
  background: #0a0c0d;
  min-height: 100vh;
  padding-bottom: 6rem;
  position: relative;

  footer {
    position: absolute;
    bottom: 1rem;
    width: 100%;
    p {
      color: rgba(255, 255, 255, 0.5);
      text-align: center;
      font-size: 0.9rem;
      @media (max-width: 600px) {
        font-size: 0.8rem;
      }
    }
  }

  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2rem 3rem;
    padding-top: 4rem;
    align-items: center;
    grid-gap: 1rem;

    @media (max-width: 1100px) {
      grid-template-columns: 1fr;
      padding: 3rem 2rem;
    }
    @media (max-width: 600px) {
      padding: 3rem 1rem;
    }

    &__image {
      width: 100%;
      display: block;
      max-width: 800px;
      margin-left: auto;
      @media (max-width: 1100px) {
        order: 1;
        margin: auto;
        max-width: 700px;
      }
    }
    &__info {
      width: 100%;
      max-width: 440px;
      @media (max-width: 1100px) {
        order: 2;
        margin: auto;
        margin-top: 2rem;
      }

      &__heading {
        font-size: 2.35rem;
        font-weight: 700;
        color: #fff;
        line-height: 135%;

        @media (max-width: 600px) {
          font-size: 1.9rem;
          line-height: 120%;
        }
        @media (max-width: 480px) {
          font-size: 1.5rem;
          line-height: 130%;
        }

        &__animation {
          display: inline-block;
          position: relative;
          width: 80px;
          height: 21px;
          //background: red;
          //  padding: .1rem;
          top: 0;
          // height: 00px;

          // background: red;

          @media (max-width: 600px) {
            width: 65px;
          }
          @media (max-width: 480px) {
            width: 50px;
          }

          img {
            position: absolute;
            top: 0;
            // bottom:-7px;
            // bottom: -10px;
            // bottom: 13px;
            //top: -30px;
            // height: auto;
            // @supports (-webkit-touch-callout: none) {
            //   top: 0px;
            // }

            transform: translateY(-22px);
            -webkit-transform: translateY(-22px) !important;
            -ms-transform: translateY(-22px) !important;
            width: 75px;
            @media (max-width: 600px) {
              width: 60px;
              transform: translateY(-12px);
              -webkit-transform: translateY(-12px) !important;
              -ms-transform: translateY(-12px) !important;
              //bottom: -7px;
              //  bottom: 13px;
            }
            @media (max-width: 480px) {
              width: 48px;
              transform: translateY(-0.35rem);
              -webkit-transform: translateY(-0.35rem) !important;
              -ms-transform: translateY(-0.35rem) !important;

              @supports (-webkit-touch-callout: none) {
                transform: translateY(-0.1rem);
                -webkit-transform: translateY(-0.1rem) !important;
                -ms-transform: translateY(-0.1rem) !important;
              }
            }
          }

          b {
            animation: show 0.5s linear 1;
            color: #fdb62f;
            display: inline-block;
            // transform-origin: 50% 50%;
            // top: 0;
            @keyframes show {
              from {
                // color: #fff;
                // opacity: 0.4;
                // transform: scale(1.4);
                // font-weight: 900;
              }
              to {
                // color: #fdb62f;
                // opacity: 1;
                // transform: scale(1);
                // font-weight: 700;
              }
            }
          }
        }
      }

      &__desc {
        font-family: "Roboto";
        color: #fff;
        font-size: 0.9rem;
        line-height: 21px;
        font-weight: 300;
        @media (max-width: 600px) {
          margin-top: 0.7rem;
        }
      }
      &__socials {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.7rem;
        margin-top: 2rem;
        @media (min-width: 1100px) {
          width: calc(100% - 0rem);
        }
      }

      &__social {
        background: rgba(255, 255, 255, 0.05);
        border: 2px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        width: 100%;
        min-height: 60px;
        display: grid;
        grid-template-columns: 50px 1fr;
        padding: 0.3rem 1.5rem;
        align-items: center;
        cursor: pointer;
        @media (max-width: 600px) {
          padding: 0.1rem 0.7rem;
          grid-template-columns: 40px 1fr;
          border: 1px solid rgba(255, 255, 255, 0.1);
        }
        @media (max-width: 400px) {
          min-height: 55px;
        }

        &__blurred {
          opacity: 0.25;
          background: rgba(128, 128, 128, 0.338) !important;
        }

        img {
          width: 40px;
          @media (max-width: 600px) {
            width: 38px;
          }
        }
        &:last-child {
          img {
            width: 35px;
            @media (max-width: 600px) {
              width: 31px;
            }
          }
        }
        h3 {
          line-height: 1.1;
          color: #fff;
          font-weight: 400;
          font-size: 0.75rem;
          @media (max-width: 600px) {
            font-size: 0.75rem;
          }
          @media (max-width: 400px) {
            font-size: 0.6rem;
          }

          b {
            font-size: 1rem;
            font-weight: 700;
            @media (max-width: 600px) {
              font-size: 1rem;
            }
            @media (max-width: 400px) {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}
