.navBar {
  padding: 0 3rem;
  height: 70px;
  display: flex;
  align-items: center;

  @media (max-width: 1100px) {
    padding: 0rem 2rem;
  }
  @media (max-width: 600px) {
    padding: 0rem 1rem;
  }

  &__logo {
    width: 120px;
    @media (max-width: 600px) {
      width: 100px;
    }
  }
}
